.changeIndicatorCol {
  width: 14px;
}

.changeIndicator {
  composes: changeIndicatorCol;
  padding: 0 !important;
  text-align: center !important;
  vertical-align: middle !important;
}
